/* reset  */
* {
  border: 0;
  padding: 0;
  box-sizing: border-box;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

/* fonts */
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800&display=swap");

.hide-scroll-bar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
/* Hide scrollbar for Chrome, Safari and Opera */
.hide-scroll-bar::-webkit-scrollbar {
  display: none;
}
